import React from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"

import Seo from "../components/seo"

export default function NotFound({ data }) {
  return (
  <Layout>
<Seo
      title="404 - page not found"
      description="The page you are looking for is not available."
    />

    <div id="content">
      <div className="static toc">
        <section id="static-no-hero">&nbsp;</section>
        <section id="static-page" className="page-pad">
          <div className="page">
            <div className="column-single">
              <h2>Sorry, this page does not exist</h2>
         
                <Link to="/awards">
                  Find out how to participate in the Scholastic Awards<br></br>
                </Link>

                <Link to="/awards/scholarships">
                  Interested in our current scholarship offerings?{" "}<br></br>
                </Link>

                <Link to="/programs">
                  Learn more about our programs for students and educators<br></br>
                </Link>

                <Link to="/gallery">
                  Visit our online gallery to see award-winning student art and
                  writing<br></br>
                </Link>

                <Link to="/support">
                  Support the Awards with a tax-deductible donation<br></br>
                </Link>

                <Link to="/regions">
                  Find your region for information about deadlines, ceremonies,
                  and exhibitions<br></br>
                </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  </Layout>
)}

export const query = graphql`
  query {
    site {
      siteMetadata {
        author
        description
        title
      }
    }
  }
`
